import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Seo from '../components/Seo'

const AccreditationsPage = ({ data }) => {
  const accreditations = data.allSanityAccreditation.nodes

  const accreditationElements = accreditations.map(({title, description, link, logo}) => (
    <article key={title} className="accreditation">
      <img src={logo.asset.url} alt={title} />
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={link} target='_blank' rel='noreferrer'>Find out more</a>
      </div>
    </article>
  ))

  return (
    <StyledAccreditationsPage>
      <Seo title='Our Accreditations' pathname='/accreditations' />
      <h1>Our Accreditations</h1>

      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        Our Accreditations
      </p>

      <section id='accreditation-collection'>
        {accreditationElements}
      </section>
    </StyledAccreditationsPage>
  )
}

export const query = graphql`
  query AccreditationsPageQuery {
    allSanityAccreditation(filter: {show: {eq: true}}) {
      nodes {
        title
        description
        link
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`

const StyledAccreditationsPage = styled.main`
  max-width: 1500px;
  margin: auto;
  padding: 5rem 4rem 4rem 4rem;

  h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  section#accreditation-collection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    article.accreditation {
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      grid-gap: 1rem;

      img {
        width: 100%;
        object-fit: contain;
      }

      h2 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }

      p {
        /* font-size: 0.9rem; */
        margin-bottom: 1.25rem;
      }

      a {
        color: var(--primary-color);
        font-size: 0.9rem;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 768px) {
    section#accreditation-collection {
      article.accreditation {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem; 
    section#accreditation-collection {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
  }
`

export default AccreditationsPage
